<template>
    <main>
        <section class="o-section">
            <div class="o-container is-fullhd">
                <article class="c-article" v-if="page !== null">
                    <h1 class="c-article__headline" v-html="page.title"></h1>
                    <div class="c-article__content" v-if="page.content" v-html="page.content"></div>
                </article>
                <div v-if="page !== null">
                    <page-component v-for="(comp, index) in page.builder" :comp="comp" :key="index"/>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
  import PageComponent from '@/components/PageComponent'
  import { EventBus } from '@/event-bus'
  import axios from 'axios'

  export default {
    name: 'Page',

    metaInfo () {
      return {
        title: this.pageTitle,
        titleTemplate: 'Müller Holzbearbeitung – %s'
      }
    },

    data() {
      return {
        page: null,
        pageTitle: null
      }
    },

    created() {
      this.fetchData()
    },

    beforeDestroy() {
      EventBus.$emit('pageLoaded', false)
    },

    methods: {
      fetchData() {
        let path = this.$route.path

        axios.get(process.env.VUE_APP_PAGES_URL + '&filter[slug]=' + path.replace('/page/', ''))
            .then(res => {
              this.page = res.data.entries[0]
              this.pageTitle = this.page.title
              EventBus.$emit('pageLoaded', true)
            })
            .catch(e => {
              /*eslint no-console: ["error", { allow: ["error"] }] */
              console.error(e)
            })
      }
    },

    components: {
      PageComponent
    }
  }
</script>
